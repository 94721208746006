import React, { useEffect, useState, useContext } from "react"
import axios from "axios"
import classNames from "classnames"
import { parseISO, getMinutes } from "date-fns"
import { formatToTimeZone } from "date-fns-timezone"
import slug from "slug"


import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"


const DayItem = ({ event, isLoggedIn, tokenString }) => {


	const [showSuccessCancel, setShowSuccessCancel] = useState(false)
	const [showSuccessRegister, setShowSuccessRegister] = useState(false)

	// Uses 1.29 of date-fns formatting
  const formatWithTimezone = (date, displayFormat) => {
    const storedDate = new Date(date)

    const timeZone =  "Africa/Abidjan" //"America/New_York"
    const displayedTime = formatToTimeZone(storedDate, displayFormat, {
      timeZone,
    })
    return `${displayedTime}`
  }

	const generateEventTime = (startDatetime, endDatetime) => {
    const startDate = startDatetime
    if (endDatetime) {
      const endDate = endDatetime
      if (
        formatWithTimezone(startDate, "a") !== formatWithTimezone(endDate, "a")
      ) {
        return `${
          getMinutes(parseISO(startDate)) === 0
            ? formatWithTimezone(startDate, "ha")
            : formatWithTimezone(startDate, "h:mma")
        }–${
          getMinutes(parseISO(endDate)) === 0
            ? formatWithTimezone(endDate, "ha")
            : formatWithTimezone(endDate, "h:mma")
        }`
      } else {

        // has endtime but times are identical
        if(formatWithTimezone(startDate, "h:mm") == formatWithTimezone(endDate, "h:mm")){
          return getMinutes(parseISO(startDate)) === 0
            ? formatWithTimezone(startDate, "ha")
            : formatWithTimezone(startDate, "h:mma")
        }

        // if not identical
        return `${
          getMinutes(parseISO(startDate)) === 0
            ? formatWithTimezone(startDate, "h")
            : formatWithTimezone(startDate, "h:mm")
        }–${
          getMinutes(parseISO(endDate)) === 0
            ? formatWithTimezone(endDate, "ha")
            : formatWithTimezone(endDate, "h:mma")
        }`
      }
    } else {
      return getMinutes(parseISO(startDate)) === 0
        ? formatWithTimezone(startDate, "ha")
        : formatWithTimezone(startDate, "h:mma")
    }
  }

  const onRSVP = async (rsvp, links) => {

    if(rsvp.canRsvp){
      // can rsvp

      const body = {
        method: (rsvp.current && (rsvp.current.status == 'approved' || rsvp.current.status == 'waitinglist')) ? 'cancel_rsvp' : 'rsvp',
        contents: { token: tokenString },
        path: links.rsvp
      }

      axios
      .post("/.netlify/functions/vipUsers", body)
      .then(res => {
        console.log(res)

        if(res.data){
      		setShowSuccessCancel(true)
      		setShowSuccessRegister(true)
        }

      })


    }else if(rsvp.canRsvp && rsvp.current && (rsvp.current.status == 'approved' || rsvp.current.status == 'waitinglist')){

    	const body = {
        method: 'cancel_rsvp',
        contents: { token: tokenString },
        path: links.rsvp
      }

      axios
      .post("/.netlify/functions/vipUsers", body)
      .then(res => {
        console.log(res)

        if(res.data){
      		setShowSuccessCancel(true)
      		setShowSuccessRegister(true)
        }
      })


    }else{
      console.log('no rsvp')
    }

  }

	return (
		<li
	    className={classNames("event", {
	      "is-vip": isLoggedIn && event.isVip && event.links.rsvp,
	    })}
	  >
	    <div>
	      <Link className="time_title_wrapper"  to={`/program/events/${slug(event.title)}_${formatWithTimezone(event.dateFrom, "D-M-YYYY")}`} >
	        <span className="time">
	          {generateEventTime((event.dateFrom !== undefined) ? event.dateFrom : event.startDatetime, (event.dateTo !== undefined) ? event.dateTo : event.endDatetime)}
	        </span>
	        <span className="title">
	            <span>{event.title}</span>
	            {event.subtitle !== null && event.subtitle !== '' && <span>{event.subtitle}</span>}
	        </span>
	      </Link>
	      {isLoggedIn && (event.vipEvent || event.isVip) && (event.rsvpUrl || event.rsvp) && event.rsvp.canRsvp && (
	        <button
	          className="rsvp"
	          disabled={showSuccessCancel || showSuccessRegister ? 'disabled' : false }
	          onClick={() => { onRSVP(event.rsvp, event.links) }}
	        >
	          { (event.rsvp.current && (event.rsvp.current.status == 'approved' || event.rsvp.current.status == "waitinglist")) 
              ? (showSuccessCancel ? 'Success' : (event.rsvp.current.status == 'approved') ? 'Cancel' : 'Leave Waitlist') 
              : ( showSuccessRegister ? 'Success' : (event.rsvp && event.rsvp.isAtCapacity) ? 'Join Waitlist' : 'RSVP') }
	        </button>
	      )}
	    </div>
	  </li>
	)

}

export default DayItem