import React, { useState } from "react"
import classnames from "classnames"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

import { useHomePage } from "~queries/hooks"

import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleHeader from "~components/page/components/pageArticle/components/pageArticleHeader/pageArticleHeader"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import PageArticleFooter from "~components/page/components/pageArticle/components/pageArticleFooter/pageArticleFooter"
import PageArticleLink from "~components/page/components/pageArticle/components/pageArticleLink/pageArticleLink"


import EditItemCard from '~components/pages/edits/components/editItemCard'

import "./edit-slideshow.scss"



const EditSlideshow = ({ location }) => {
  const { editsSlideshow } = useHomePage()

  const [showThird, setShowThird ] = useState(false)

  const handleClick = () => {
    if(showThird){
      setShowThird(false)
    }else{
      setShowThird(true)
    }
  }

  const handleScroll = (e) => {
    console.log(e.target.scrollLeft)

    if(e.target.scrollLeft > 5){
      setShowThird(true)
    }else{
      setShowThird(false)
    }

  }

  const pageTitle = 'Untitled Edit'


  return (
  	 <PageArticle title={pageTitle} className={classnames(["page__article edit_article", {reveal: showThird}])}>
      {editsSlideshow && editsSlideshow.length > 0 ? <PageArticleHeader header={pageTitle} /> : null}
      <PageArticleSection className="partners">
          {editsSlideshow && editsSlideshow.length > 0 ? (
            <div className={classnames(["edit_hover" + (editsSlideshow.length >= 3 ? ' at_least_three' : ''), {reveal: showThird}])} onScroll={handleScroll}>
              <button className="reveal_third" onClick={handleClick}></button>
              <div className="edit_container">
                {editsSlideshow.map(n => (
                  <EditItemCard 
                    key={n._id}
                    noTarget={false} 
                    title={n.title} 
                    byline={n.byline} 
                    color={n.color} 
                    image={n.image}
                  />
                  ))}
                </div>
            </div>
          ) : null}
      </PageArticleSection>
      { editsSlideshow && editsSlideshow.length > 0 ?
        <PageArticleFooter>
          <PageArticleLink to="/edits" copy={'See All'} />
        </PageArticleFooter> : null 
      }
    </PageArticle>
  )

}

export default EditSlideshow