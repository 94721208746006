import React, { useState, useEffect, useContext } from "react"
import { motion, useAnimation } from "framer-motion"
import classnames from "classnames"

/* Import Global Context(s) */
import IntroContext from "~context/intro"
import HeaderContext from "~context/header"

/* Import Local Style(s) */
import "./intro-animation.scss"

/* 

Intro Animation

The 'Intro Animation' has three components

The first is the Text that appears to the User on Load that says:

  Untitled
  { date }
  { location }
  ART

This is an absolutely positioned container the full width and height of the screen.
It uses Framer to increase the opacity and height of `date`, and `location`, respectively. 
When it is done, it fades out 'Untitled' instantly, and fades out the opacity of the remaining characters.

This allows us to sync with the second component of the animation, the 'fake' container that just holds
an additional copy of the same content as the first component so that it mirrors screen position and placement.
It is an absolutely positioned container that contains four lines of content:

  Untitled
  ""
  ""
  ""

This keeps 'Untitled' in the correct place. In its last step, it uses a `layout` animation to transition from 
being centered on the page, i.e. from `justify-content: center;` to `justify-content: flex-start;` with `framer motion.`
It uses the addition of a CSS class to allow CSS to handle the font size change to prevent stretching.

Finally, the third component is an empty div which takes up 100% height of the page that animations to 0%. 
This component is positioned `relative` such that it pushes the additional content off of the page, then lets them slide in. 

*/

const IntroAnimation = () => {
  const [animate] = useState(true)
  const [isIntroDone, setIsIntroDone] = useContext(IntroContext)
  const [isHeaderReady, setIsHeaderReady] = useContext(HeaderContext)
  const [isTransition, setIsTransition] = useState(false)
  const infoContainerControls = useAnimation()
  const dateControls = useAnimation()
  const locationControls = useAnimation()
  const titleControls = useAnimation()
  const textTransitionContainerControls = useAnimation()
  const contentAnimationControls = useAnimation()

  useEffect(() => {
    const startAnimation = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000))
      if (animate) {
        // show location
        await locationControls.start({
          height: "1em",
          opacity: 1,
          transition: { duration: 0 },
        })
        // wait 1 second
        await new Promise(resolve => setTimeout(resolve, 1000))
        // show date
        await dateControls.start({
          height: "1em",
          opacity: 1,
          transition: { duration: 0 },
        })
        // wait 1 second
        await new Promise(resolve => setTimeout(resolve, 1500))
        // fade out container
        infoContainerControls.start({
          opacity: 0,
        })
        // fade out title text in info container immediately
        titleControls.start({
          opacity: 0,
          transition: { duration: 0 },
        })
        //
        contentAnimationControls.start({ height: "0vh" })
        // trigger text transition
        setIsTransition(true)
        // wait 1 second

        setIsHeaderReady(true)

        await new Promise(resolve => setTimeout(resolve, 750))

        // remove animation
        setIsIntroDone(true)
        
      }
    }
    startAnimation()
  }, [
    animate,
    dateControls,
    infoContainerControls,
    titleControls,
    locationControls,
    textTransitionContainerControls,
    contentAnimationControls,
    setIsTransition,
    setIsIntroDone,
    setIsHeaderReady
  ])

  if (isIntroDone) {
    return null
  }

  return (
    <>
      <div className="page__animation">
        {/* this changes size to allow lower content  */}
        <motion.div
          className={classnames("page__animation__animation-container", {
            animated: animate,
          })}
        >
          {/* info container animation */}
          <motion.div
            className={classnames(
              "page__animation__animation-container__info-animation"
            )}
            animate={infoContainerControls}
          >
            <motion.p className="title" animate={titleControls}>
              Untitled
            </motion.p>
            <motion.p className="location" animate={locationControls}>
              Miami Beach
            </motion.p>
            <motion.p className="date" animate={dateControls}>
              4–8 Dec 2024
            </motion.p>
            <motion.p className="art">Art</motion.p>
          </motion.div>
          {/* fake container for text transition */}
          <motion.div
            className={classnames(
              "page__animation__animation-container__text-animation",
              { "is-transition": isTransition }
            )}
          >
            <motion.p className="title" layout>
              
            </motion.p>
            <motion.p
              className="location"
              animate={locationControls}
            ></motion.p>
            <motion.p className="date" animate={dateControls}></motion.p>
            <motion.p className="art">Art</motion.p>
          </motion.div>
        </motion.div>
      </div>
      {/* Content Animation - Pushes Content 100% Height of Screen Down Until Done */}
      <motion.div
        layout
        className="content-animation"
        animate={contentAnimationControls}
      ></motion.div>
    </>
  )
}

export default IntroAnimation
